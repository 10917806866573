<template>
  <div class="coursevideo">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-page-header @back="goBack" ></el-page-header>
      <el-breadcrumb-item>课程资料</el-breadcrumb-item>
      <el-breadcrumb-item>{{prames.title}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{prames.type}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="coursemater" v-for="(item,index) in fileslist" :key="index">
      <p class="tit">{{item.FileName}}</p>
      <iframe  width="600" height="400"  frameborder="0" ref="iframeDom" name="iframe" id="iframe" scrolling="no" allowfullscreen :src="item.Url"></iframe>
    </div>
  </div>
</template>

<script>
import { get_materials_details } from '@/api/classesinfo'
export default {
  name: 'coursemater_coursevideo',
  data () {
    return {
      prames:{},
      fileslist:[]
    };
  },
  created () {
    var prames = sessionStorage.getItem('stu_coursemater_query')? JSON.parse(sessionStorage.getItem('stu_coursemater_query')):{}
    this.prames=prames;
    this.getInit()
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    async getInit () {
      let prames = {
        userid: this.prames.userid,
        SolutionID:this.prames.SolutionID,
        FileType:this.prames.FileType
      }
      let res = await get_materials_details(prames)
      if (res.flag == '1') {
        var File= res.datainfo.File;
        this.fileslist = File;
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    },
  },

}
</script>
<style scoped lang="scss">
.coursevideo {
  font-size: 16px;
  color: #343434;
  .tit {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 25px;
  }
  .video-player {
    width: 70%;
    margin-top: 20px;
    border-radius: 15px;
    overflow: hidden;
  }
}
</style>
<style lang="scss">
.video-js .vjs-big-play-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background-color: rgba(43, 51, 63, 0.5);
}
</style>